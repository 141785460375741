import { Link } from 'gatsby';
import * as React from 'react';
import Footer from '../components/footer';
import Shell from '../layout/shell';
import './terms.scss';

interface Props {}

const Terms: React.FC<Props> = (props) => {
  return (
    <Shell>
      <div className="header-stripe">Privacy Policy</div>
      <div className="terms fixed-width">
        <h2>Website privacy notice</h2>
        <h3>1. INTRODUCTION</h3>
        <p>
          This privacy notice provides you with details of how we collect and process your personal data through your
          use of our site <a href="https://www.techforgoodbb.co.uk/">https://www.techforgoodbb.co.uk/</a>, including any
          information you may provide through our site when you purchase a product or service, sign up to our newsletter
          or take part in a prize draw or competition. The site is operated by Rocketmakers Limited.
        </p>
        <p>By providing us with your data, you warrant to us that you are over 13 years of age.</p>
        <p>
          Rocketmakers are the data controllers and we are responsible for your personal data (referred to as “we”, “us”
          or “our” in this privacy notice).
        </p>
        <p>
          We have appointed a Data Protection Officer who is in charge of privacy related matters for us. If you have
          any questions about this privacy notice, please contact the Data Protection Officer using the details set out
          below.
        </p>
        <p>Contact Details</p>
        <p>Our full details are:</p>
        <p>Full name of legal entity: Rocketmakers Limited</p>
        <p>
          Main email address: <a href="mailto:operations@rocketmakers.com">operations@rocketmakers.com</a>
        </p>
        <p>Postal address: The Data Protection Officer, Rocketmakers Ltd, 20 Manvers Street, Bath, BA1 1JW</p>
        <p>Telephone number: UK: +44 1225 436192</p>
        <p>
          If you are not happy with any aspect of how we collect and use your data, you have the right to complain to
          the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (
          <a href="www.ico.org.uk">www.ico.org.uk</a>). We should be grateful if you would contact us first if you do
          have a complaint so that we can try to resolve it for you.
        </p>
        <p>
          It is very important that the information we hold about you is accurate and up to date. Please let us know if
          at any time your personal information changes by emailing us at{' '}
          <a href="mailto:operations@rocketmakers.com">operations@rocketmakers.com</a>.
        </p>
        <h3>2. WHAT DATA DO WE COLLECT ABOUT YOU</h3>
        <p>
          Personal data means any information capable of identifying an individual. It does not include anonymised data.
        </p>
        <p>We may process certain types of personal data about you as follows:</p>
        <ul>
          <li>
            Identity Data may include your first name, maiden name, last name, username, marital status, title, date of
            birth and gender.
          </li>
          <li>Contact Data may include your billing address, delivery address, email address and telephone numbers.</li>
          <li>Financial Data may include your bank account and payment card details.</li>
          <li>
            Transaction Data may include details about payments between us and other details of purchases made by you.
          </li>
          <li>
            Technical Data may include your login data, internet protocol addresses, browser type and version, browser
            plug-in types and versions, time zone setting and location, operating system and platform and other
            technology on the devices you use to access this site.
          </li>
          <li>
            Profile Data may include your username and password, purchases or orders, your interests, preferences,
            feedback and survey responses.
          </li>
          <li>Usage Data may include information about how you use our website, products and services.</li>
          <li>
            Marketing and Communications Data may include your preferences in receiving marketing communications from us
            and our third parties and your communication preferences.
          </li>
        </ul>
        <p>
          We may also process Aggregated Data from your personal data but this data does not reveal your identity and as
          such in itself is not personal data. An example of this is where we review your Usage Data to work out the
          percentage of website users using a specific feature of our site. If we link the Aggregated Data with your
          personal data so that you can be identified from it, then it is treated as personal data.
        </p>
        <h4>Sensitive Data</h4>
        <p>
          We do not collect any Sensitive Data about you. Sensitive data refers to data that includes details about your
          race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade
          union membership, information about your health and genetic and biometric data. We do not collect any
          information about criminal convictions and offences.
        </p>
        <p>
          Where we are required to collect personal data by law, or under the terms of the contract between us and you
          do not provide us with that data when requested, we may not be able to perform the contract (for example, to
          deliver goods or services to you). If you don’t provide us with the requested data, we may have to cancel a
          product or service you have ordered but if we do, we will notify you at the time.
        </p>
        <h3>3. HOW WE COLLECT YOUR PERSONAL DATA</h3>
        <p>We collect data about you through a variety of different methods including:</p>
        <ul>
          <li>
            Direct interactions: You may provide data by filling in forms on our site (or otherwise) or by communicating
            with us by post, phone, email or otherwise, including when you:
          </li>
          <li>order our products or services;</li>
          <li>subscribe to our publications;</li>
          <li>request resources or marketing be sent to you;</li>
          <li>enter a competition, prize draw, promotion or survey; or</li>
          <li>give us feedback.</li>
          <li>
            Automated technologies or interactions: As you use our site, we may automatically collect Technical Data
            about your equipment, browsing actions and usage patterns. We collect this data by using cookies, server
            logs and similar technologies. We may also receive Technical Data about you if you visit other websites that
            use our cookies.
          </li>
          <li>
            Third parties or publicly available sources: We may receive personal data about you from various third
            parties and public sources as set out below
          </li>
        </ul>
        <ol>
          <li>Analytics providers such as Google based outside the EU</li>
          <li>Search information providers Google based outside the EU.</li>
          <li>
            Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as
            Stripe based outside the EU; PayPal based inside the EU; XERO based outside the EU.
          </li>
          <li>Electronic Mail Service Providers such as MailChimp based outside the EU.</li>
        </ol>
        <h3>4. HOW WE USE YOUR PERSONAL DATA</h3>
        <p>
          We will only use your personal data when legally permitted. The most common uses of your personal data are:
        </p>
        <ul>
          <li>Where we need to perform the contract between us.</li>
          <li>
            Where it is necessary for our legitimate interests (or those of a third party) and your interests and
            fundamental rights do not override those interests.
          </li>
          <li>Where we need to comply with a legal or regulatory obligation.</li>
        </ul>
        <p>
          Generally, we do not rely on consent as a legal ground for processing your personal data, other than in
          relation to sending marketing communications to you via email or text message. You have the right to withdraw
          consent to marketing at any time by emailing us at firstcontact@rocketmakers.com.
        </p>
        <p>Purposes for processing your personal data </p>
        <p>
          Set out below is a description of the ways we intend to use your personal data and the legal grounds on which
          we will process such data. We have also explained what our legitimate interests are where relevant.
        </p>
        <p>
          We may process your personal data for more than one lawful ground, depending on the specific purpose for which
          we are using your data. Please email us at firstcontact@rocketmakers.com if you need details about the
          specific legal ground we are relying on to process your personal data where more than one ground has been set
          out in the table below:
        </p>
        <table>
          <tr>
            <th>Purpose/Activity</th>
            <th>Type of data</th>
            <th>Lawful basis for processing</th>
          </tr>
          <tr>
            <td>To register you as a new customer</td>
            <td>
              (a) Identity <br />
              (b) Contact
            </td>
            <td>Performance of a contract with you</td>
          </tr>
          <tr>
            <td>
              To process and deliver your service including: <br />
              (a) Manage payments, fees and charges <br />
              (b) Collect and recover money owed to us
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Financial
              <br />
              (d) Transaction
              <br />
              (e) Marketing and Communications
            </td>
            <td>
              (a) Performance of a contract with you
              <br />
              (b) Necessary for our legitimate interests to recover debts owed to us
            </td>
          </tr>
          <tr>
            <td>
              To manage our relationship with you which will include:
              <br />
              (a) Notifying you about changes to our terms or privacy policy
              <br />
              (b) Asking you to leave a review, testimonial or take a survey
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Profile
              <br />
              (d) Marketing and Communications
            </td>
            <td>
              (a) Performance of a contract with you
              <br />
              (b) Necessary to comply with a legal obligation
              <br />
              (c) Necessary for our legitimate interests to keep our records updated and to study how customers use our
              products/services
            </td>
          </tr>
          <tr>
            <td>To enable you to partake in a prize draw, competition or complete a survey</td>
            <td>
              (a) Identity <br />
              (b) Contact <br />
              (c) Profile <br />
              (d) Usage <br />
              (e) Marketing and Communications <br />
              (f) Technical
            </td>
            <td>
              Necessary for our legitimate interests to study how customers use our products/services, to develop them,
              to grow our business and to inform our marketing strategy
            </td>
          </tr>
          <tr>
            <td>
              To use data analytics to improve our website, products/services, marketing, customer relationships and
              experiences
            </td>
            <td>
              (a) Technical
              <br />
              (b) Usage
            </td>
            <td>
              Necessary for our legitimate interests to define types of customers for our products and services, to keep
              our site updated and relevant, to develop our business and to inform our marketing strategy
            </td>
          </tr>
          <tr>
            <td>
              To make suggestions and recommendations to you about goods or services that may be of interest to you
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Technical
              <br />
              (d) Usage
              <br />
              (e) Profile
            </td>
            <td>Necessary for our legitimate interests to develop our products/services and grow our business</td>
          </tr>
        </table>
        <h4>Marketing communications</h4>
        <p>You will receive marketing communications from us if you have:</p>
        <ul>
          <li>requested information from us or purchased goods or services from us; or</li>
          <li>
            if you provided us with your details and ticked the box at the point of entry of your details for us to send
            you marketing communications; and
          </li>
          <li>in each case, you have not opted out of receiving that marketing.</li>
        </ul>
        <p>
          We will get your express opt-in consent before we share your personal data with any third party for marketing
          purposes.
        </p>
        <p>
          You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out
          links on any marketing message sent to you or by emailing us at firstcontact@rocketmakers.com at any time.
        </p>
        <p>
          Where you opt out of receiving our marketing communications, this will not apply to personal data provided to
          us as a result of a product/service purchase, warranty registration, product/service experience or other
          transactions.
        </p>
        <h4>Change of purpose</h4>
        <p>
          We will only use your personal data for the purposes for which we collected it, unless we reasonably consider
          that we need to use it for another reason and that reason is compatible with the original purpose. If you wish
          to find out more about how the processing for the new purpose is compatible with the original purpose, please
          email us at operations@rocketmakers.com.
        </p>
        <p>
          If we need to use your personal data for a purpose unrelated to the purpose for which we collected the data,
          we will notify you and we will explain the legal ground of processing.
        </p>
        <p>
          We may process your personal data without your knowledge or consent where this is required or permitted by
          law.
        </p>
        <h3>5. DISCLOSURES OF YOUR PERSONAL DATA</h3>
        <p>
          We may have to share your personal data with the parties set out below for the purposes set out in the table
          in paragraph 4 above:
        </p>
        <ul>
          <li>
            Other companies in our group who provide IT and system administration services and undertake leadership
            reporting.
          </li>
          <li>Service providers who provide IT and system administration services.</li>
          <li>
            Professional advisers including lawyers, bankers, auditors and insurers who provide consultancy, banking,
            legal, insurance and accounting services.
          </li>
          <li>
            HM Revenue & Customs, regulators and other authorities based in the United Kingdom and other relevant
            jurisdictions who require reporting of processing activities in certain circumstances.
          </li>
          <li>Third parties to whom we sell, transfer, or merge parts of our business or our assets.</li>
        </ul>
        <p>
          We require all third parties to whom we transfer your data to respect the security of your personal data and
          to treat it in accordance with the law. We only allow such third parties to process your personal data for
          specified purposes and in accordance with our instructions.
        </p>
        <h3>6. DATA SECURITY</h3>
        <p>
          We have put in place appropriate security measures to prevent your personal data from being accidentally lost,
          used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal
          data to those employees, agents, contractors and other third parties who have a business need to know such
          data. They will only process your personal data on our instructions and they are subject to a duty of
          confidentiality.
        </p>
        <p>
          We have put in place procedures to deal with any suspected personal data breach and will notify you and any
          applicable regulator of a breach where we are legally required to do so.
        </p>
        <h3>7. DATA RETENTION</h3>
        <p>
          We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for,
          including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          To determine the appropriate retention period for personal data, we consider the amount, nature, and
          sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data and whether we can achieve those purposes
          through other means, and the applicable legal requirements.
        </p>
        <p>
          By law we have to keep basic information about our customers (including Contact, Identity, Financial and
          Transaction Data) for six years after they cease being customers for tax purposes.
        </p>
        <p>In some circumstances you can ask us to delete your data: see below for further information.</p>
        <p>
          In some circumstances we may anonymise your personal data (so that it can no longer be associated with you)
          for research or statistical purposes in which case we may use this information indefinitely without further
          notice to you.
        </p>
        <h3>8. YOUR LEGAL RIGHTS</h3>
        <p>
          Under certain circumstances, you have rights under data protection laws in relation to your personal data.
          These include the right to:
        </p>
        <li>Request access to your personal data.</li>
        <li>Request correction of your personal data.</li>
        <li>Request erasure of your personal data.</li>
        <li>Object to processing of your personal data.</li>
        <li>Request restriction of processing your personal data.</li>
        <li>Request transfer of your personal data.</li>
        <li>Right to withdraw consent.</li>
        <p>
          You can see more about these rights at:{' '}
          <a
            href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
            target="_blank"
          >
            https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
          </a>
        </p>
        <p>
          If you wish to exercise any of the rights set out above, please email us at{' '}
          <a href="mailto:operations@rocketmakers.com">operations@rocketmakers.com</a>
        </p>
        <p>
          You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However,
          we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively,
          we may refuse to comply with your request in these circumstances.
        </p>
        <p>
          We may need to request specific information from you to help us confirm your identity and ensure your right to
          access your personal data (or to exercise any of your other rights). This is a security measure to ensure that
          personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask
          you for further information in relation to your request to speed up our response.
        </p>
        <p>
          We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month
          if your request is particularly complex or you have made a number of requests. In this case, we will notify
          you and keep you updated.
        </p>
        <h3>9.THIRD-PARTY LINKS </h3>
        <p>
          This website may include links to third-party websites, plug-ins and applications. Clicking on those links or
          enabling those connections may allow third parties to collect or share data about you. We do not control these
          third-party websites and are not responsible for their privacy statements. When you leave our website, we
          encourage you to read the privacy notice of every website you visit.
        </p>
        <h3>10. COOKIES</h3>
        <p>
          You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access
          cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible
          or not function properly.
        </p>
      </div>
      <Footer />
    </Shell>
  );
};

export default Terms;
